import React, { createRef } from "react"
import Bg from "../../images/bg.png"
import { BackBtn } from "../../components/icons"
import PropertyCard from "../../components/propertyCard"
import Slider from "react-slick"
import OceannaLogo from "../../assets/oceania/oceannaLogo.png"
import ceruleanImage from '../../assets/oceania/cerulean/gallery/sirilian 1.jpg'
import indigoImg from '../../assets/oceania/indigo/gallery/Cerulean AP 1.jpg'
import { SampleNextArrow, SamplePrevArrow } from "../../components/arrowButtons"

const Property = () => {
  const customeSlider = createRef();
  const gotoNext = () => {
    customeSlider.current.slickNext()
  };

  const gotoPrev = () => {
    customeSlider.current.slickPrev()
  }
  const data = [
    {
      id: 1,
      title: "THE CERULEAN ",
      imgUrl: ceruleanImage,
      link: "oceanna/cerulean",
      text:
        "The Cerulean an icon of the future. Architecturally inspired by the sculpted beauty of seashells, which punctuate the shores of Victoria Island...",
    },
    {
      id: 2,
      title: "Indigo ",
      imgUrl: indigoImg,
      link: "oceanna/indigo",
      text:
        "Indigo an icon of the future. Architecturally inspired by the sculpted beauty of seashells, which punctuate the shores of Victoria Island...",
    },
  ]

  const settings = {
    dots: true,
    dotsClass: "line-dots property",
    slidesToShow: 2,
    slidesToScroll: 1,
    // className: "center",
    // centerMode: true,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <>
          <div data-dots={i + 1} className="dots">
            &nbsp;
          </div>
        </>
      )
    },
  }



  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="flex flex-col w-full">
        <div className="relative md:flex md:justify-center md:items-stretch md:w-full">
          <div
            className="relative min-h-full md:min-h-screen py-12 px-6 md:p-12 md:w-full md:flex md:items-center md:justify-center"
            style={{
              backgroundImage: `url(${Bg} )`,
            }}
          >
            <div className="relative md:absolute top-0 left-0 right-0 md:pr-6 md:pl-6 md:mr-16 md:ml-16 md:mt-12 flex-row flex justify-between items-center margin">
              <div className="w-1/3">
                <BackBtn
                  color={"#982A7D"}
                  height={"20px"}
                  onClick={() => window.history.back()}
                />
              </div>
              <div className="md:w-1/3 my-4 md:my-0 mx-auto">
                <img src={OceannaLogo} className="mx-auto m-0" />
              </div>
              <div className="md:w-1/3 text-right mx-auto">
              </div>
            </div>
            <div>
              <SamplePrevArrow onClick={gotoPrev} />
              <section className="max-w-4xl w-full flex justify-center">
                <Slider
                  {...settings}
                  ref={customeSlider}
                  className="overflow-hidden py-12 md:mt-24 property-slide"
                >
                  {data.map((el, key) => (
                    <PropertyCard key={key} {...el} />
                  ))}
                </Slider>
              </section>

              <SampleNextArrow onClick={gotoNext} />
            </div>

            {/* <section className="flex justify-center items-center mt-24 space-x-12">
              {data.map((el, key) => (
                <PropertyCard key={key} {...el} />
              ))}
            </section> */}

          </div>
        </div>
      </div>
    </div>
  )
}
export default Property
